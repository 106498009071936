// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-2021-contact-js": () => import("./../../../src/pages/2021/contact.js" /* webpackChunkName: "component---src-pages-2021-contact-js" */),
  "component---src-pages-2021-faq-js": () => import("./../../../src/pages/2021/faq.js" /* webpackChunkName: "component---src-pages-2021-faq-js" */),
  "component---src-pages-2021-index-js": () => import("./../../../src/pages/2021/index.js" /* webpackChunkName: "component---src-pages-2021-index-js" */),
  "component---src-pages-2021-live-js": () => import("./../../../src/pages/2021/live.js" /* webpackChunkName: "component---src-pages-2021-live-js" */),
  "component---src-pages-2021-mechanics-js": () => import("./../../../src/pages/2021/mechanics.js" /* webpackChunkName: "component---src-pages-2021-mechanics-js" */),
  "component---src-pages-2021-problems-2021-js": () => import("./../../../src/pages/2021/problems-2021.js" /* webpackChunkName: "component---src-pages-2021-problems-2021-js" */),
  "component---src-pages-2022-contact-js": () => import("./../../../src/pages/2022/contact.js" /* webpackChunkName: "component---src-pages-2022-contact-js" */),
  "component---src-pages-2022-faq-js": () => import("./../../../src/pages/2022/faq.js" /* webpackChunkName: "component---src-pages-2022-faq-js" */),
  "component---src-pages-2022-index-js": () => import("./../../../src/pages/2022/index.js" /* webpackChunkName: "component---src-pages-2022-index-js" */),
  "component---src-pages-2022-mechanics-js": () => import("./../../../src/pages/2022/mechanics.js" /* webpackChunkName: "component---src-pages-2022-mechanics-js" */),
  "component---src-pages-2022-problems-2022-js": () => import("./../../../src/pages/2022/problems-2022.js" /* webpackChunkName: "component---src-pages-2022-problems-2022-js" */),
  "component---src-pages-2023-contact-js": () => import("./../../../src/pages/2023/contact.js" /* webpackChunkName: "component---src-pages-2023-contact-js" */),
  "component---src-pages-2023-faq-js": () => import("./../../../src/pages/2023/faq.js" /* webpackChunkName: "component---src-pages-2023-faq-js" */),
  "component---src-pages-2023-index-js": () => import("./../../../src/pages/2023/index.js" /* webpackChunkName: "component---src-pages-2023-index-js" */),
  "component---src-pages-2023-mechanics-js": () => import("./../../../src/pages/2023/mechanics.js" /* webpackChunkName: "component---src-pages-2023-mechanics-js" */),
  "component---src-pages-2023-problems-2023-js": () => import("./../../../src/pages/2023/problems-2023.js" /* webpackChunkName: "component---src-pages-2023-problems-2023-js" */),
  "component---src-pages-2024-contact-js": () => import("./../../../src/pages/2024/contact.js" /* webpackChunkName: "component---src-pages-2024-contact-js" */),
  "component---src-pages-2024-faq-js": () => import("./../../../src/pages/2024/faq.js" /* webpackChunkName: "component---src-pages-2024-faq-js" */),
  "component---src-pages-2024-index-js": () => import("./../../../src/pages/2024/index.js" /* webpackChunkName: "component---src-pages-2024-index-js" */),
  "component---src-pages-2024-mechanics-js": () => import("./../../../src/pages/2024/mechanics.js" /* webpackChunkName: "component---src-pages-2024-mechanics-js" */),
  "component---src-pages-2024-problems-2024-js": () => import("./../../../src/pages/2024/problems-2024.js" /* webpackChunkName: "component---src-pages-2024-problems-2024-js" */),
  "component---src-pages-2025-contact-js": () => import("./../../../src/pages/2025/contact.js" /* webpackChunkName: "component---src-pages-2025-contact-js" */),
  "component---src-pages-2025-faq-js": () => import("./../../../src/pages/2025/faq.js" /* webpackChunkName: "component---src-pages-2025-faq-js" */),
  "component---src-pages-2025-index-js": () => import("./../../../src/pages/2025/index.js" /* webpackChunkName: "component---src-pages-2025-index-js" */),
  "component---src-pages-2025-mechanics-js": () => import("./../../../src/pages/2025/mechanics.js" /* webpackChunkName: "component---src-pages-2025-mechanics-js" */),
  "component---src-pages-2025-problems-2025-js": () => import("./../../../src/pages/2025/problems-2025.js" /* webpackChunkName: "component---src-pages-2025-problems-2025-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-live-js": () => import("./../../../src/pages/live.js" /* webpackChunkName: "component---src-pages-live-js" */),
  "component---src-pages-mechanics-js": () => import("./../../../src/pages/mechanics.js" /* webpackChunkName: "component---src-pages-mechanics-js" */),
  "component---src-pages-problems-js": () => import("./../../../src/pages/problems.js" /* webpackChunkName: "component---src-pages-problems-js" */)
}

